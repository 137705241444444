'use client'

import updateApplyPackage from '@lib/api/patients/trials/updateApplyPackage'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'
import type { ApplySuccessPageProps } from 'app/p/apply-success/[token]/[step]/page'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'

// Context used for the patient dossier page at /s/patient-handoff
export const PostApplyContext = createContext<
  | {
      advanceToNextStep: () => void
      consentToShareYourStory: boolean
      initializeState: (props: ApplySuccessPageProps) => void
      isLoading: boolean
      persistWhyApplyToTrial: (consentedToShare?: boolean) => Promise<void>
      setShareYourStoryModalOpened: Dispatch<SetStateAction<boolean>>
      setWhyApplyToTrial: Dispatch<SetStateAction<string | undefined>>
      shareYourStoryModalOpened: boolean
      trialApplicationIdToken: string
      whyApplyToTrial?: string
    }
  | undefined
>(undefined)

function PostApplyProvider({ children }: { children: ReactNode }) {
  const [consentToShareYourStory, setConsentToShareYourStory] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shareYourStoryModalOpened, setShareYourStoryModalOpened] =
    useState<boolean>(false)
  const [whyApplyToTrial, setWhyApplyToTrial] = useState<string | undefined>()
  const [trialApplicationIdToken, setTrialApplicationIdToken] =
    useState<string>('')

  function initializeState(props: ApplySuccessPageProps) {
    setTrialApplicationIdToken(props.idToken)
    setWhyApplyToTrial(props.whyAreYouApplying ?? undefined)
    setConsentToShareYourStory(props.consentToShareYourStory ?? false)
  }

  const advanceToNextStep = () => {
    trackEvent(TrackingEvents.POST_TRIAL_APPLY_NEXT_BUTTON_CLICKED, {
      source: TrackingSources.PATIENT_POST_APPLY,
      trialApplicationIdToken: trialApplicationIdToken,
    })
  }

  async function persistWhyApplyToTrial(consentedToShare?: boolean) {
    setIsLoading(true)
    if (consentedToShare) {
      setConsentToShareYourStory(true)
    }
    await updateApplyPackage({
      patientTrialApplicationIdToken: trialApplicationIdToken,
      payload: {
        data: {
          consentToShareYourStory: consentedToShare,
          whyAreYouApplying: whyApplyToTrial,
        },
        label: 'personalDetails',
      },
    })
    trackEvent(TrackingEvents.POST_TRIAL_APPLY_QUESTION_MODIFIED, {
      source: TrackingSources.TRIAL_APPLICATION_FORM,
      trialApplicationIdToken: trialApplicationIdToken,
      val: whyApplyToTrial,
    })

    setIsLoading(false)
  }

  return (
    <PostApplyContext.Provider
      value={{
        advanceToNextStep,
        consentToShareYourStory,
        initializeState,
        isLoading,
        persistWhyApplyToTrial,
        setShareYourStoryModalOpened,
        setWhyApplyToTrial,
        shareYourStoryModalOpened,
        trialApplicationIdToken,
        whyApplyToTrial,
      }}
    >
      {children}
    </PostApplyContext.Provider>
  )
}

export function usePostApply() {
  const context = useContext(PostApplyContext)
  if (context === undefined) {
    throw new Error(
      'usePostApply must be used within a PostApplyContext.Provider',
    )
  }
  return context
}

export default PostApplyProvider
